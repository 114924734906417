<template>
  <div>
    <AppCard sheet>
      <IconInfoBlue class="m-auto" />
      <AppCardImageTitle :title="$t('SendMoneyVoltPaymentCancelled.Title').value">
        <div class="description">
          {{
            $t('SendMoneyVoltPaymentCancelled.Description', {
              openBankingName,
            }).value
          }}
        </div>
      </AppCardImageTitle>
      <ButtonGroup>
        <AppButton analytics-name="send-failed-try-again" @click="goToPayments">
          {{ $t('SendMoneyVoltPaymentCancelled.ChangePaymentMethod').value }}
        </AppButton>
        <AppButton
          theme="secondary"
          analytics-name="send-failed-visit-help-centre"
          @click="goToSummary"
        >
          {{ $t('SendMoneyVoltPaymentCancelled.TryAgain').value }}
        </AppButton>
      </ButtonGroup>
    </AppCard>
  </div>
</template>

<script>
import { ref, onBeforeMount, computed } from '@vue/composition-api'

import { useRouter } from '@galileo/composables/useRouter'

import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'
import { AppButton, AppCardImageTitle, AppCard } from '@oen.web.vue2/ui'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

import { IconInfoBlue } from '@oen.web.vue2/icons'

import {
  useI18nStore,
  useAuthStore,
  useAnalyticsStore,
  useSendMoneyStore,
  useAppStore,
} from '@galileo/stores'
import useOpenBanking from '@galileo/composables/useOpenBanking'

export default {
  name: 'SendMoneyVoltPaymentCancelled',
  components: {
    ButtonGroup,
    AppCardImageTitle,
    AppButton,
    AppCard,
    IconInfoBlue,
  },
  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()
    const sendMoneyStore = useSendMoneyStore()
    const authStore = useAuthStore()
    const { isPayTo } = useOpenBanking()
    const openBankingName = isPayTo.value
      ? 'PayTo'
      : $t('SendMoneyVoltPaymentCancelled.VoltDescription').value
    sendMoneyStore.resetVoltProps()
    const userCountry = authStore.userProfile.country

    const goToPayments = async () => {
      await analyticsStore.track({
        event: SEGMENT_EVENTS.CHANGE_PAYMENT_METHOD_TRIGGERED,
        traits: {
          sendMethod: sendMoneyStore.form.paymentMethod,
          accountType: authStore.user.customer.accountType,
        },
      })
      sendMoneyStore.setActiveStep('/send-money/payment')
      await router.push('/send-money/payment')
    }

    const goToSummary = async () => {
      await analyticsStore.track({
        event: SEGMENT_EVENTS.TRY_AGAIN_TRIGGERED,
        traits: {
          sendMethod: sendMoneyStore.form.paymentMethod,
          accountType: authStore.user.customer.accountType,
        },
      })
      await router.replace('/send-money/summary')
    }

    return {
      userCountry,
      goToPayments,
      goToSummary,
      $t,
      openBankingName,
    }
  },
}
</script>

<style scoped>
.description {
  @apply pt-4 text-base text-secondary-text leading-6 font-normal;
}
</style>
